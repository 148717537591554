@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .bg-black {
    background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
  }

  /* #0e0f0f */
  .bg-gray-900 {
    background-color: rgb(14 15 15 / var(--tw-bg-opacity)) !important;
  }

  .text-gray-900 {
    color: rgb(14 15 15 / var(--tw-text-opacity)) !important;
  }

  .border-gray-900 {
    border-color: rgb(14 15 15 / var(--tw-border-opacity)) !important;
  }

  /* #272823 */
  .bg-gray-800 {
    background-color: rgb(39 40 45 / var(--tw-bg-opacity)) !important;
  }

  .text-gray-800 {
    color: rgb(39 40 45 / var(--tw-text-opacity)) !important;
  }

  .border-gray-800 {
    border-color: rgb(39 40 45 / var(--tw-border-opacity)) !important;
  }

  /* #1d1e1e */
  .bg-gray-700 {
    background-color: rgb(29 30 30 / var(--tw-bg-opacity)) !important;
  }

  .text-gray-700 {
    color: rgb(29 30 30 / var(--tw-text-opacity)) !important;
  }

  .border-gray-700 {
    border-color: rgb(29 30 30 / var(--tw-border-opacity)) !important;
  }

  /* #333333 */
  .bg-gray-600 {
    background-color: rgb(51 51 51 / var(--tw-bg-opacity)) !important;
  }

  .text-gray-600 {
    color: rgb(51 51 51 / var(--tw-text-opacity)) !important;
  }

  .border-gray-600 {
    border-color: rgb(51 51 51 / var(--tw-border-opacity)) !important;
  }

  /* #272828 */
  .bg-gray-500 {
    background-color: rgb(39 40 40 / var(--tw-bg-opacity)) !important;
  }

  .text-gray-500 {
    color: rgb(39 40 40 / var(--tw-text-opacity)) !important;
  }

  .border-gray-500 {
    border-color: rgb(39 40 40 / var(--tw-border-opacity)) !important;
  }

  /* #4c4d4f */
  .bg-gray-400 {
    background-color: rgb(76 77 79 / var(--tw-bg-opacity)) !important;
  }

  .border-gray-400 {
    border-color: rgb(76 77 79 / var(--tw-border-opacity)) !important;
  }

  /* #0ba8ff */
  .bg-sky-400 {
    background-color: rgb(11 170 255 / var(--tw-bg-opacity)) !important;
  }

  .text-sky-400 {
    color: rgb(11 170 255 / var(--tw-text-opacity)) !important;
  }

  .border-sky-400 {
    border-color: rgb(11 170 255 / var(--tw-border-opacity)) !important;
  }

  /* #0072ce */
  .bg-indigo-500 {
    background-color: rgb(0 114 206 / var(--tw-bg-opacity)) !important;
  }

  .text-indigo-500 {
    color: rgb(0 114 206 / var(--tw-text-opacity)) !important;
  }

  /* #084684 */
  .bg-indigo-600 {
    background-color: rgb(8 70 132 / var(--tw-bg-opacity)) !important;
  }

  .text-indigo-600 {
    color: rgb(8 70 132 / var(--tw-text-opacity)) !important;
  }

  .bg-indigo-700 {
    background-color: rgb(2 61 120 / var(--tw-border-opacity)) !important;
  }

  .link-bg-gray-700 {
    background-color: rgb(30 31 72 / var(--tw-bg-opacity)) !important;
  }

  .bg-gray-300 {
    background-color: rgb(224 224 252 / var(--tw-bg-opacity)) !important;
  }

  .bg-gray-100 {
    background-color: rgb(246 245 255 / var(--tw-bg-opacity)) !important;
  }

  .text-gray-400 {
    color: rgb(152 165 192 / var(--tw-text-opacity)) !important;
  }

  .text-gray-300 {
    color: rgb(224 224 252 / var(--tw-text-opacity)) !important;
  }

  .text-gray-50 {
    color: rgb(249 250 251 / var(--tw-text-opacity)) !important;
  }

  .text-gray-100 {
    color: rgb(243 244 246 / var(--tw-text-opacity)) !important;
  }

  .border-gray-300 {
    border-color: rgb(224 224 252 / var(--tw-border-opacity)) !important;
  }

  .border-gray-200 {
    border-color: rgb(239 240 254 / var(--tw-border-opacity)) !important;
  }

  .border-indigo-600 {
    border-color: rgb(8 70 132 / var(--tw-border-opacity)) !important;
  }

  .border-indigo-700 {
    border-color: rgb(2 61 120 / var(--tw-border-opacity)) !important;
  }
}

.error-message {
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.row-selected {
  @apply dark:bg-gray-400 bg-gray-100;
}

.row-clicked {
  @apply bg-sky-400 text-gray-100;
}

.td,
.th {
  @apply border py-1 px-4 border-gray-200 dark:border-indigo-500 dark:border-opacity-40;
}

.pagination-link {
  @apply ltr:float-left rtl:float-right data-[active=true]:bg-gray-200 data-[active=true]:dark:link-bg-gray-700 hover:bg-gray-100 dark:hover:link-bg-gray-700 data-[active=true]:cursor-default relative cursor-pointer ml-0.5 no-underline rounded border border-solid border-transparent px-4 py-2;
}

.simple-pagination-link {
  @apply ltr:float-left text-lg rtl:float-right dark:text-gray-600 group-data-[show=true]:dark:text-gray-50 relative cursor-pointer ml-0.5 no-underline rounded border border-solid border-transparent px-1 py-1;
}

.scrollbars::-webkit-scrollbar {
  width: 6px;
}

.scrollbars::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.scrollbars::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

.scrollbars.show::-webkit-scrollbar-thumb,
.scrollbars:hover::-webkit-scrollbar-thumb {
  background: #777;
}

svg {
  vertical-align: middle;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

.show::-webkit-scrollbar-thumb,
:hover::-webkit-scrollbar-thumb {
  background: #777;
}
