body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  height: 100vh;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-tree-node-selected {
  background-color: transparent !important;
}

.ant-tree-node-content-wrapper-normal:hover {
  background-color: transparent !important;
}

.ant-tree-node-content-wrapper:hover {
  background-color: transparent !important;
}
.dark .css-166bipr-Input,
.dark .css-1jqq78o-placeholder,
.dark .css-1dimb5e-singleValue,
.dark .css-qbdosj-Input {
  color: #111827 !important;
}

.css-166bipr-Input,
.css-1jqq78o-placeholder,
.css-1dimb5e-singleValue,
.css-qbdosj-Input {
  color: #111827 !important;
}
